import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
import {locale} from '@/assets/db/db.conf.ts';

@Options({
    name: 'customer-info-component',
    props: {
        user: {
            type: Object
        },
        img64: {
            type: Boolean,
            default: false
        }
    }
})

export default class CustomerInfoComponent extends Vue {
    public locale$: any = locale;
    public locale: any = null;
    public user = {
        name: '',
        surname: '',
        fatherName: '',
        pin: '',
        document: '',
        image: 'test',
        birthDate: '',
        address: '',
    };

    public width = 0;


    get isUser() {
        if (this.$route.matched[0].name === 'user') {
            return true;
        } else {
            return false;
        }
    }

    public created(): void {
        this.locale$.subscribe((res: any) => {
            if (res && res.organisationInner) {
                this.locale = res.services.customerInfo;
            }
        });
    }

    public mounted() {
        this.width = window.innerWidth;
        window.addEventListener('resize', () => {
            this.width = window.innerWidth;
        });
    }

    get customerData(): Array<object> {
        return [
            {
                title: `${this.locale.documentNumber}:`,
                data: this.user.document
            },
            {
                title: `${this.locale.dateOfBirth}:`,
                data: this.user.birthDate
            },
            {
                title: `${this.locale.identificationNumber}:`,
                data: this.user.pin
            },
            {
                title: `${this.locale.registeredAddress}:`,
                data: this.user.address
            },
            {
                title: `${this.locale.userName}:`,
                data: this.user.name + ' ' + this.user.surname + ' ' + this.user.fatherName
            },
        ];
    }
}
