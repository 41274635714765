<style src="./local-mobile-card.component.scss" lang="scss"></style>
<template>
  <div class="local-mobile-card mt-20">
    <div class="row">
      <div class="col-12">
        <accordion-component>
          <v-card class="mb-10" v-for="(item, index) in callHistory" :key="index">
            <accordion-item-component>
              <template v-slot:title>
                <div class="information">
                  <div class="information__title">
                    {{ locale.dialTheNumber }}
                  </div>
                  <div class="information__description">
                    {{ item.number }}
                  </div>
                </div>
              </template>
              <template v-slot:content>
                <div class="information mt-20">
                  <div class="information__title">
                    {{ locale.date }}
                  </div>
                  <div class="information__description">
                    {{ item.date }}
                  </div>
                </div>
                <div class="information">
                  <div class="information__title">
                    {{ locale.time }}
                  </div>
                  <div class="information__description">
                    {{ item.time }}
                  </div>
                </div>
                <div class="information-card mt-20">
                  <div class="information__title">
                    {{ locale.period }}
                  </div>
                  <div class="information__description">
                    {{ item.period }}
                  </div>
                </div>
              </template>
            </accordion-item-component>
          </v-card>
        </accordion-component>
      </div>
    </div>
  </div>
</template>
<script src="./local-mobile-card.component.ts"></script>
