import 'reflect-metadata';
import {mixins, Options, Vue} from 'vue-class-component';
import {container} from 'tsyringe';
import {store} from '@/store';
import CustomerInfoComponent from '@/core/shared/customer-info/customer-info.component.vue';
import LocalTableCardComponent
    from '@/pages/local-talks-service/components/local-table-card/local-table-card.component.vue';
import {LocalTalksServiceSandbox} from '@/pages/local-talks-service/sandbox/local-talks-service.sandbox';
import {IGetNumber} from '@/pages/local-talks-service/types/get-number';
import {IUser} from '@/core/auth/store/state';
import {IGetCallHistory} from '@/pages/local-talks-service/types/get-call-history';
import ServiceNameComponent from '@/core/shared/service-name/service-name.component.vue';
import NotDataComponent from '@/core/shared/not-data/not-data.component.vue';
import {IServiceCommon} from '@/types/service-common';
import {IServiceHeader} from '@/types/service-header';
import {IPayloadNumber} from '@/types/payload-number';
import LocalMobileCardComponent
    from '@/pages/local-talks-service/components/local-mobile-card/local-mobile-card.component.vue';
import {CommonSandbox} from '@/sandbox/common.sandbox';
import {Router} from '@/core/mixin/router';


@Options({
    name: 'local-talks-service-component',
    components: {
        CustomerInfoComponent,
        LocalTableCardComponent,
        ServiceNameComponent,
        NotDataComponent,
        LocalMobileCardComponent
    },
    provide: {
        callHistory: store.getters._getCallHistory
    }
})

export default class LocalTalksServiceComponent extends mixins(Router) {
    public service = 1072;
    public sandbox = container.resolve(LocalTalksServiceSandbox);
    public sandboxCommon: CommonSandbox = container.resolve(CommonSandbox);
    private store = store;
    public pageNumber = 1;
    public pageSize = 10;
    public select = 0;
    public $refs!: {
        pagination: {
            reset: () => void;
        },
    };
    public requestNumber = true;

    get userData(): IUser {
        if (localStorage.getItem('mainToken')?.length && this.requestNumber) {
            this.getServiceData();
            this.requestNumber = false;
            let payload: IPayloadNumber = {
                token: String(localStorage.getItem('mainToken')),
                service: this.service
            };
            this.sandbox.getNumbers(payload);
        }
        return store.getters._getUserFullData;
    }

    get serviceHeader(): IServiceHeader {
        return this.store.getters._getCommonServiceHeader;
    }

    get numbers(): IGetNumber[] {
        return this.store.getters._getNumbers;
    }

    get callHistory(): IGetCallHistory[] {
        return this.store.getters._getCallHistory;
    }

    get totalCountCallHistory(): number {
        return this.store.getters._getTotalCountCallHistory;
    }

    get commonService(): IServiceCommon {
        return this.store.getters._getCommonService;
    }

    public resetPagination() {
        if (this.pageNumber > 1) {
            this.$refs.pagination.reset();
            this.pageNumber = 1;
        }
    }


    public changeSelectNumber(selectNumber: any): void {
        this.resetPagination();
        let payload = {
            token: localStorage.getItem('mainToken'),
            number: selectNumber,
            pageSize: this.pageSize,
            pageNumber: this.pageNumber,
        };
        this.sandbox.getCallHistory(payload);
        this.select = selectNumber;
    }

    public changePage(info: any) {
        this.pageNumber = info.pageNumber;
        this.pageSize = info.pageSize;
        let payload = {
            token: localStorage.getItem('mainToken'),
            number: this.select,
            pageSize: info.pageSize,
            pageNumber: info.pageNumber,
        };
        this.sandbox.getCallHistory(payload);
        window.scroll(0, this.$el.querySelector('#table').offsetTop);
    }
}
