<style src="./local-talks-service.component.scss" lang="scss"></style>

<template>
  <div class="information-phone-service">
    <div class="container-fluid v-container">
      <div class="row">
        <div class="col-12">
          <section class="mt-20">
            <service-name-component
                :serviceName="commonService.name"
                :organization="commonService.structureName"
                :img="commonService.file"
                :payment="commonService.paymentRequired"
                :registered="commonService.authRequired"
                :serviceId="commonService.id"
                :structureId="commonService.structureId"
                :headers="commonService.serviceHeader"
            />
          </section>
          <section>
            <customer-info-component :user="userData"/>
          </section>
          <section class="mt-30 mb-100">
            <v-card>
              <local-table-card-component
                  :numbers="numbers"
                  :callHistory="callHistory"
                  @changeSelectNumber="changeSelectNumber"
                  v-if="numbers?.length"
              />
              <not-data-component class="mt-20" v-if="(numbers?.length === 0) && userData.name">
                Hal-hazırda sizin adınıza aid telefon
                nömrəsi yoxdur.
              </not-data-component>
            </v-card>
            <local-mobile-card-component class="d-block d-lg-none" :callHistory="callHistory"/>
            <v-pagination
                ref="pagination"
                :limit="pageSize"
                :dataCount="totalCountCallHistory"
                :page="pageNumber"
                @changePage="changePage"
                class="mt-40 mb-100  "/>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./local-talks-service.component.ts"></script>
