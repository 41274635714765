<style src="./customer-info.component.scss" lang="scss"></style>

<template>
  <v-card class="customer-info my-20" v-if="user?.name?.length">
    <div class="row">
      <div class="col-md-2" :class="{'col-3':!isUser, 'col-12':isUser}" v-if="user?.image">
        <div class="customer-info__image" :class="{'m-0':width<=758}" >
          <img v-if="!img64" :src="user?.image" alt="user photo" width="140"/>
          <img v-else :src="`data:image/png;base64, ${user.image}`" alt="user photo" width="140"/>
        </div>
      </div>
      <div class="col-7 p-0" v-if="width<=992 && !isUser">
        <div class="customer-info__group">
          <div class="customer-info__field">
            {{ locale.userName }}
          </div>
          <div class="customer-info__data">{{ this.user.name + ' ' + this.user.surname + ' ' + this.user.fatherName }}
          </div>
        </div>
      </div>
      <div class="col-12 col-md-10  customer-info__list" v-if="width>=992 || isUser ">
        <div class="row">
          <div class="col-12 col-lg-5">
            <div class="customer-info__group">
              <div class="customer-info__field">
                {{ locale.userName }}
              </div>
              <div class="customer-info__data">
                {{ `${this.user.surname} ${this.user.name} ${this.user?.fatherName}` }}
              </div>
            </div>
            <div class="customer-info__group">
              <div class="customer-info__field">
                {{ locale.identificationNumber }}
              </div>
              <div class="customer-info__data">{{ this.user.pin }}</div>
            </div>
            <div class="customer-info__group">
              <div class="customer-info__field">
                Sənədin verilmə tarixi:
              </div>
              <div class="customer-info__data">{{ this.user.dateOfIssue }}</div>
            </div>
<!--            <div class="customer-info__group">-->
<!--              <div class="customer-info__field">-->
<!--                {{ locale.dateOfBirth }}-->
<!--              </div>-->
<!--              <div class="customer-info__data">{{ this.user.birthDate }}</div>-->
<!--            </div>-->
            <div class="customer-info__group" v-if="this.user.birthAddress">
              <div class="customer-info__field">
                Doğum yeri
              </div>
              <div class="customer-info__data">{{ this.user.birthAddress }}</div>
            </div>
          </div>
          <div class="col-12 col-lg-4">
            <div class="customer-info__group">
              <div class="customer-info__field">
                Sənədin seriya və nömrəsi:
              </div>
              <div class="customer-info__data">{{ this.user.document }}</div>
            </div>
            <div class="customer-info__group">
              <div class="customer-info__field">
                {{ locale.dateOfBirth }}
              </div>
              <div class="customer-info__data">{{ this.user.birthDate }}</div>
            </div>
            <div class="customer-info__group">
              <div class="customer-info__field">
                Sənədi verən orqan
              </div>
              <div class="customer-info__data">{{ this.user.registeredDepartmentName }}</div>
            </div>
            <div class="customer-info__group" v-if="this.user.address">
              <div class="customer-info__field">
                {{ locale.registeredAddress }}
              </div>
              <div class="customer-info__data">{{ this.user.address }}</div>
            </div>


          </div>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script src="./customer-info.component.ts"></script>
